import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy on its security response plan.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`A Security Response Plan (SRP) provides the impetus for security and
business teams to integrate their efforts from the perspective of
awareness and communication, as well as coordinated response in times of
crisis (security vulnerability identified or exploited). Specifically,
an SRP defines a product description, contact information, escalation
paths, expected service level agreements (SLA), severity and impact
classification, and mitigation/remediation timelines. By requiring
business units to incorporate an SRP as part of their business
continuity operations and as new products or services are developed and
prepared for release to consumers, ensures that when an incident occurs,
swift mitigation and remediation ensues.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`The purpose of this policy is to establish the requirement that all
business units supported by the Infosec team develop and maintain a
security response plan. This ensures that security incident management
team has all the necessary information to formulate a successful
response should a specific security incident occur.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy applies any established and defined business unity or entity
within the Data Migrators.`}</p>
    <h2>{`Policy`}</h2>
    <p>{`The development, implementation, and execution of a Security Response
Plan (SRP) are the primary responsibility of the specific business unit
for whom the SRP is being developed in cooperation with the Infosec
Team. Business units are expected to properly facilitate the SRP for
applicable to the service or products they are held accountable. The
business unit security coordinator or champion is further expected to
work with the Data Migrators Security Team in the
development and maintenance of a Security Response Plan.`}</p>
    <h3>{`Service or Product Description`}</h3>
    <p>{`The product description in an SRP must clearly define the service or
application to be deployed with additional attention to data flows,
logical diagrams, architecture considered highly useful.`}</p>
    <h3>{`Contact Information`}</h3>
    <p>{`The SRP must include contact information for dedicated team members to
be available during non-business hours should an incident occur and
escalation be required. This may be a 24/7 requirement depending on the
defined business value of the service or product, coupled with the
impact to customer. The SRP document must include all phone numbers and
email addresses for the dedicated team member(s).`}</p>
    <h3>{`Triage`}</h3>
    <p>{`The SRP must define triage steps to be coordinated with the security
incident management team in a cooperative manner with the intended goal
of swift security vulnerability mitigation. This step typically includes
validating the reported vulnerability or compromise.`}</p>
    <h3>{`Identified Mitigations and Testing`}</h3>
    <p>{`The SRP must include a defined process for identifying and testing
mitigations prior to deployment. These details should include both
short-term mitigations as well as the remediation process.`}</p>
    <h3>{`Mitigation and Remediation Timelines`}</h3>
    <p>{`The SRP must include levels of response to identified vulnerabilities
that define the expected timelines for repair based on severity and
impact to consumer, brand, and company. These response guidelines should
be carefully mapped to level of severity determined for the reported
vulnerability.`}</p>
    <h2>{`Policy Compliance`}</h2>
    <h3>{`Compliance Measurement`}</h3>
    <p>{`Each business unit must be able to demonstrate they have a written SRP
in place, and that it is under version control and is available via the
web. The policy should be reviewed annually.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to this policy must be approved by the Infosec Team in
advance and have a written record.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`Any business unit found to have violated (no SRP developed prior to
service or product deployment) this policy may be subject to delays in
service or product release until such a time as the SRP is developed and
approved. Responsible parties may be subject to disciplinary action, up
to and including termination of employment, should a security incident
occur in the absence of an SRP`}</p>
    <h2>{`Related Documents`}</h2>
    <p>{`None.`}</p>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`None.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      